<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVO Popups </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'popupManagerPanel' }"
                    >Popup manager</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'popupManagerCrearPanel' }"
                    >Crear</router-link
                  >
                </p>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            

            <v-form @submit.prevent="guardar()" :disabled="processForm" ref="createForm">
              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="error"
                    v-model="alertError"
                    dismissible
                    ref="mensajeError"
                  >
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="Nombre (*)"
                    v-model="popupManager.nombre"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    dense
                    label="URL (*)"
                    v-model="popupManager.url"
                  >
                  </v-text-field>
                </v-col>


                <!-- <v-col cols="12">
                  <p class="my-0">Contenido</p>
                  <ckeditor
                    :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                    v-model="popupManager.contenido"
                    :config="{
                      filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                      allowedContent: true,
                    }"
                  ></ckeditor>
                </v-col> -->

                <v-col xl="6" lg="6" md="6" sm="12" cols="12">
                  <p>Imagen:</p>
                  <input
                    style="display: none"
                    type="file"
                    ref="iptImagen"
                    @change.prevent="cargarImagen"
                    accept="image/*"
                  />
                  <div class="custom-my-dropzone">
                    <v-row>
                      <v-col cols="12" class="text-center" v-show="urlImagen === ''">
                        <v-btn
                          :loading="loaderImagen"
                          color="primary"
                          @click="
                            $refs.iptImagen.value = '';
                            $refs.iptImagen.click();
                          "
                        >
                          <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGEN
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="text-center" v-show="urlImagen !== ''">
                        <v-btn icon color="red" small @click.prevent="eliminarImagen">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-img :src="urlImagen" height="200" contain> </v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>


                <v-col cols="12">
                  <p class="red--text"></p>
                  <v-select
                    dense
                    item-text="texto"
                    item-value="valor"
                    :items="resources.estados"
                    label="Estado(*)"
                    outlined
                    v-model="popupManager.estado"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn
                    class="mr-2"
                    type="submit"
                    :loading="processForm"
                    color="success"
                    large=""
                  >
                    <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                  </v-btn>
                  <v-btn
                    :to="{ name: 'popupManagerPanel' }"
                    type="button"
                    :loading="processForm"
                    color="error"
                    large=""
                  >
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      popupManager: {
        nombre: "",
        url: "",
        contenido: "",
        imagen: "",
        estado: 1,
      },
      resources: {
        estados: [
          {
            texto: "Habilitado",
            valor: 1,
          },
          {
            texto: "Inhabilitado",
            valor: 0,
          },
        ],
      },
      processForm: false,
      listErrors: [],
      alertError: false,
      loaderImagen: false,
      urlImagen: "",
    };
  },

  methods: {
    cargarImagen(event) {
      this.loaderImagen = true;

      if (this.urlImagen !== "") {
        this.$toasted.error("Ya hay un archivo cargado");
        this.loaderImagen = false;
        return;
      }

      if (event.target.files.length > 0) {
        const imagen = event.target.files[0];
        this.urlImagen = URL.createObjectURL(imagen);
        this.popupManager.imagen = imagen;
      }

      this.loaderImagen = false;
    },

    eliminarImagen() {
      this.urlImagen = "";
      this.popupManager.imagen = "";
    },

    guardar() {
      this.alertError = false;
      this.processForm = true;


      // this.$refs.createForm.validate();

      this.axios({
        method: "POST",
        url: "api/panel/popup-manager",
        data: this.serialize(this.popupManager),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "popupManagerPanel",
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al guardar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("popup-manager.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);
  },
};
</script>
